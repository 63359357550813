import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import {
  Bonus,
  BonusDescription,
  BonusNeeded,
  TypeBox,
  TypeDescription,
  TypeHeader,
  TypeInfo,
  TypeKind,
  TypesRow,
} from "@/game-tft/ChampionTraits.style.jsx";
import { getChampionsByTrait } from "@/game-tft/get-champions-by-trait.mjs";
import ParsedDescription from "@/game-tft/ParsedDescription.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import UnitToolTip from "@/game-tft/UnitToolTip.jsx";
import { translateTraits } from "@/game-tft/use-traits.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Card = ({ trait, bonuses, description, type, set }) => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const championsWithSameTrait = getChampionsByTrait(
    trait.apiKey,
    type === "class" ? "classes" : "origins",
    set,
  );
  const traitKey = StaticTFT.getTraitKey(trait.apiKey, set);
  return (
    <TypeBox>
      <TypeIcon name={traitKey} />
      <TypeInfo>
        <TypeHeader className="type-subtitle2">
          {translateTraits(t, traitKey, set)}{" "}
          <TypeKind className="type-overline">
            {t(`tft:traits.${type.toLowerCase()}`, type)}
          </TypeKind>
        </TypeHeader>
        {description && (
          <TypeDescription className="type-caption">
            <ParsedDescription text={description} iconSize={"var(--sp-4)"} />
          </TypeDescription>
        )}
        {bonuses.length > 0 &&
          bonuses.map((bonus, i) => (
            <Bonus key={i}>
              <BonusNeeded className="type-subtitle2">
                <span>{bonus.needed}</span>
              </BonusNeeded>
              <BonusDescription className="type-caption">
                <ParsedDescription
                  text={bonus.effect}
                  iconSize={"var(--sp-4)"}
                />
              </BonusDescription>
            </Bonus>
          ))}
        <Units>
          {championsWithSameTrait?.map((champion) => (
            <a
              href={`/tft/champion/${champion.apiKey}?set=${set?.replace(
                "set",
                "",
              )}`}
              key={champion.apiKey}
            >
              <UnitToolTip
                champInfo={champion}
                set={set}
                skinSetting={isSkinsEnabled}
                style={{ zIndex: "3" }}
              >
                <ChampionImage
                  champKey={champion.apiKey}
                  cost={champion.cost}
                  size={34}
                  set={set}
                  skinSetting={isSkinsEnabled}
                />
              </UnitToolTip>
            </a>
          ))}
        </Units>
      </TypeInfo>
    </TypeBox>
  );
};

const ChampionTraits = ({ championClasses, championOrigins, set }) => {
  return (
    <div>
      <TypesRow>
        <>
          {championClasses.map((props, i) => (
            <Card key={i} trait={props} set={set} type="class" {...props} />
          ))}
          {championOrigins.map((props, i) => (
            <Card key={i} trait={props} set={set} type="origin" {...props} />
          ))}
        </>
      </TypesRow>
    </div>
  );
};

const Units = styled("div")`
  display: flex;
  gap: var(--sp-2);
  align-items: center;
  margin-top: var(--sp-4);
  flex-wrap: wrap;

  > a:hover {
    cursor: pointer;
    filter: brightness(1.4);
  }
`;

export default ChampionTraits;
